html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.center-box {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  text-align: center; 
  background-color: white; 
  min-height: 80vh; 
  margin-top: 10vh; 
  margin-left: 15vw; 
  margin-right: 15vw;
  box-shadow: 10px 14px 5px 3px rgba(0, 0, 0, 0.15);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
